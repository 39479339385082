var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "text--black", attrs: { "bg-variant": "light" } },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-left" }, [
                _c("h1", [_vm._v("Industry Averages")])
              ]),
              _c(
                "b-col",
                { staticClass: "text-right" },
                [
                  _c("router-link", {
                    staticClass: "mr-4",
                    attrs: { to: "/admin/industry/report/" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var href = ref.href
                          var route = ref.route
                          var navigate = ref.navigate
                          return [
                            _c(
                              "b-btn",
                              {
                                attrs: {
                                  href: href,
                                  variant: "success",
                                  text: route.name
                                },
                                on: { click: navigate }
                              },
                              [_vm._v("Generate Report")]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("router-link", {
                    attrs: { to: "/admin/industry/edit/" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var href = ref.href
                          var route = ref.route
                          var navigate = ref.navigate
                          return [
                            _c(
                              "b-btn",
                              {
                                attrs: {
                                  href: href,
                                  variant: "primary",
                                  text: route.name
                                },
                                on: { click: navigate }
                              },
                              [_vm._v("Create New")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("b-table", {
            attrs: {
              striped: "",
              hover: "",
              bordered: "",
              small: "",
              items: _vm.averages,
              fields: _vm.fields,
              "primary-key": "id"
            },
            scopedSlots: _vm._u([
              {
                key: "cell(industryrevenuebucket_id)",
                fn: function(data) {
                  return [
                    data.item.industryrevenuebucket_id &&
                    data.item.industryrevenuebucket_id > 0
                      ? _c("div", [
                          data.item.bucket_max && data.item.bucket_max > 0
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        data.item.bucket_min,
                                        false
                                      )
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        data.item.bucket_max,
                                        false
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        data.item.bucket_min,
                                        false
                                      )
                                    ) +
                                    "+ "
                                )
                              ])
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "cell(companies)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(data.item.companies) +
                        " / " +
                        _vm._s(
                          _vm.getAvailableCompanyCount(data.item.industry_id)
                        ) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "cell(created)",
                fn: function(data) {
                  return [
                    _c("span", { staticClass: "small" }, [
                      _vm._v(
                        _vm._s(data.item.created) +
                          " by " +
                          _vm._s(data.item.createdby)
                      )
                    ])
                  ]
                }
              },
              {
                key: "cell(updated)",
                fn: function(data) {
                  return [
                    data.item.updated
                      ? _c("span", { staticClass: "small" }, [
                          _vm._v(
                            _vm._s(data.item.updated) +
                              " by " +
                              _vm._s(data.item.updatedby)
                          )
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "cell(quarter)",
                fn: function(data) {
                  return [
                    data.item.quarter
                      ? _c("span", [_vm._v("Q" + _vm._s(data.item.quarter))])
                      : _c("span", [_vm._v("All")])
                  ]
                }
              },
              {
                key: "cell(id)",
                fn: function(data) {
                  return [
                    _c(
                      "b-button-toolbar",
                      { staticClass: "d-block" },
                      [
                        _c(
                          "b-button-group",
                          { attrs: { size: "sm" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  title: "Regenerate Average",
                                  variant: "primary",
                                  disabled: _vm.loading
                                }
                              },
                              [
                                _c("b-icon-bootstrap-reboot", {
                                  attrs: { "aria-hidden": "true" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.regenAvg(data.item)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  title: "Edit",
                                  variant: "success",
                                  disabled: _vm.loading
                                }
                              },
                              [
                                _c("b-icon-pencil-fill", {
                                  attrs: {
                                    title: "Edit",
                                    "aria-hidden": "true"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.editAvg(data.item.id)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  title: "Delete",
                                  variant: "danger",
                                  disabled: _vm.loading
                                }
                              },
                              [
                                _c("b-icon-trash-fill", {
                                  attrs: {
                                    title: "Delete",
                                    "aria-hidden": "true"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.delAvg(data.item.id)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }